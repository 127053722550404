/* header:not(.header-home), */
footer {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    /* background: linear-gradient(180deg, #fefefe 0%, #f3f3f3 55%, #e4e4e4 100%); */
    background: #f3f3f3;
    /* color: var(--grey-dark); */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    /* width: 100%; */
}
/* footer .container,
footer .container-wide {
    display: flex;
    align-items: center;
    justify-content: center;
} */