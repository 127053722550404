:root {
    --dss-dark_blue: #28333f;
    --dss-blue: #306185;
    --dss-dark_yellow: #F1B154;
    --dss-light_yellow: #feedc7;
    --dss-yellow: #FFC665;

    --grey-semidark: #999999;
    --grey: #cccccc;
    --grey-semi: #eeeeee;
    --grey-dark: #4a4646;

    --black: #2b2b2b;
}