.info-area {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: var(--grey-semi);
    background: var(--dss-dark_blue);
    color: #fff;
}
.main-text {
    padding: 0 1.5rem;
    font-size: 1.15rem;
    max-width: 1000px;
}
.brand {
    font-size: 1.25rem;
    font-weight: 700;
    /* color: var(--black); */
}
.main-text a {
    color: var(--dss-yellow);
}
.search-title {
    margin: 3rem 0 2rem;
    color: var(--grey-dark);
}
.search-query {
    color: var(--dss-blue);
}
.text-section {
    padding-bottom: 1rem;
}
