/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;1,400&display=swap'); */


body,
button,
input,
select,
textarea {
  color: #404040;
  font-family: 'Lato', sans-serif;
  /* font-family: 'Nunito', sans-serif; */
  /* font-family: 'Trebuchet MS', 'Helvetica', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-size: 1rem;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Nunito', sans-serif;
  font-family: 'Trebuchet MS', 'Helvetica', sans-serif; */
  line-height: 1.2;
}
h1 {
  font-size: 3.5rem;
  
}
h2 {
  font-size: 2.25rem;
  
}
h2.small {
  font-size: 1.75rem;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: #eee;
  font-family: 'Courier 10 Pitch', courier, monospace;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: monaco, consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}
code {
  display: block;
  background-color: #f3f3f3;
  padding: 1rem;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 0.25rem;
  margin: 0.25rem 0;
}