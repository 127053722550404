/* Links
--------------------------------------------- */
a {
    color: var(--dss-blue);
    text-decoration: none;
  }
  
  a:hover,
  a:focus,
  a:active {
    color: var(--dss-dark_blue);
  }
  
  a:hover,
  a:active {
    outline: 0;
  }
  
  .main-menu a {
    color: var(--gray-semidark);
  }
  .main-menu a:hover {
    color: var(--dss-blue);
  }