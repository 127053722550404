.App {
  position: relative;
  min-height: 100vh;
}
main {
    padding-bottom: 10rem;
}
.container {
    max-width: 1366px;
    margin: 0 auto;
}
.note {
    font-size: 0.9rem;
}
.note.examples button {
    vertical-align: unset;
}
.error {
    color: #df4141;
}
.flex {
    display: flex;
}
.flex.justify-content-space-between {
    justify-content: space-between;
}
.gap-1 {
    gap: 1rem;
}
.align-items-center {
    align-items: center;
}