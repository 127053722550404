.modal {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5em 1em;
    z-index: 999999;
    box-sizing: border-box;
}
  
.modal.modal-fade {
    animation: fade-in 1s 1 linear;
    animation-fill-mode: forwards;
    opacity: 0;
}
    
.modal > .modal-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
    
    
.modal > .modal-body {
    z-index: 2;
    position: relative;
    margin: 0 auto;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
    padding: 2rem;
    width: 100%;
    max-width: 1000px;
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.125);
}
    
@keyframes fade-in {
    0% {
      animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.close {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
   /* padding: 1rem; */
   /* cursor: pointer; */
}
.close span { 
    cursor: pointer;
}