/* header:not(.header-home), */
header {
    box-shadow: 0px 0px 20px 7px rgb(0 0 0 / 30%);
    padding: 0.5rem 1rem;
    /* margin-bottom: 3rem; */
    background-color: var(--dss-dark_blue);
    position: relative;
    z-index: 9;
}

.logo {
    width: 200px;
    margin: 0 auto;
}

@media screen and (min-width: 650px) {
    .logo {
        margin: 0;
        width: 250px;
    }
} 

.main-navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.main-navigation ul li {
    color: #fff;
    margin: 0;
    padding: 0;
    cursor: pointer;
}